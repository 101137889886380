












import TitleBar from "@/components/TitleBar/index.vue";
import Vue from "vue";
import MyScoredMatches from "@/components/MyScoredMatches/index.vue";

export default Vue.extend({
  name: "AppMarkerScoredMatchesController",
  components: {
    MyScoredMatches,
    TitleBar,
  },
});
